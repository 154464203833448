import React, {FormEvent, useCallback, useContext, useState} from "react";
import {WithPromise} from "../../util/Loader";
import {GitBranch} from "./Branches";
import LoadingButton from "../../util/LoadingButton";
import {useNavigate} from "react-router-dom";
import {CustomApiError, CustomErrorType} from "../../api/CustomApiError";
import {DecisionModal} from "../../ui/components/DecisionModal";
import {Button, DataGrid, Field, Grid, Modal, Section, Typography} from "@matillion/component-library";
import {Api} from "../../api/Api";
import {useApi} from "@matillion/octo-react-util";
import LatestPipelineStateIconMfe from "../../ui/microFrontend/LatestPipelineStateIconMfe";
import {InitialStateContext} from "../../ui/app/InitialPage";
import CreatePullRequest from "./CreatePullRequest";

interface BranchLineArgs {
  projectName: string,
  branch: GitBranch
}
function BranchLine({projectName, branch}: BranchLineArgs) {
  let api = useApi<Api>()
  let navigate = useNavigate()
  let [error, setError] = useState<Error>()
  let {setInitialState} = useContext(InitialStateContext)
  let call = useCallback(async ()=>{
    await api.checkout(projectName, branch)
    setInitialState(await api.getInitialState())
    navigate("/state")
  }, [navigate, projectName, branch, api, setInitialState])
  let [modalVisible, setModalVisible] = useState(false)
  return <div>
    <span>{branch.branchName} - {branch.refId}</span>
    <LatestPipelineStateIconMfe branchName={branch.branchName} projectName={projectName}/>
    <div style={{display: "flex", flexDirection: "row"}}>
      <LoadingButton
          onClick={call}
          onError={setError}
      >
        Checkout
      </LoadingButton>
      <Button
          onClick={() => setModalVisible(true)}
          text={"New branch from here"}
      />
      {modalVisible ? <NewBranchModal
          onCancel={() => setModalVisible(false)}
          projectName={projectName}
          sourceBranch={branch}
      /> : null}
    </div>
    {error ? <ErrorHandler
        projectName={projectName}
        branch={branch}
        error={error}
        clearError={() => setError(undefined)}
    /> : null}
  </div>
}

interface ErrorHandlerProps {projectName: string, branch: GitBranch, error: Error, clearError:() => void}

function ErrorHandler({projectName, branch, error: e, clearError}: ErrorHandlerProps) {
  let api = useApi<Api>()
  let navigate = useNavigate()
  let {setInitialState} = useContext(InitialStateContext)

  const forceCheckout = async () => {
    await api.checkout(projectName, branch, true)
    setInitialState(await api.getInitialState())
    navigate("/state")
  }

  if(e instanceof CustomApiError) {
    switch (e.errorType) {
      case CustomErrorType.UNCOMMITTED_CHANGES:
        let decorated = [...e.data.dotfileChanges, ...e.data.nonDotfileChanges].map(x => {
          return {...x, id: x.filePath}
        })
        return (
            <DecisionModal title={e.message} closeModal={clearError} yesAction={forceCheckout}>
              <DataGrid columns={[
                {
                  key: "filePath",
                  title: "Path"
                },
                {
                  key: "type",
                  title: "Change Type"
                },
              ]} rows={decorated}/>
              Do you want to discard these changes?
            </DecisionModal>
        )
      case CustomErrorType.UNEXPECTED_REMOTE_STATE:
        return (
            <DecisionModal title={e.message} closeModal={clearError} yesAction={forceCheckout}>
              Remote branch is at ref '{e.data.remoteRef}' but the last known local commit was at '{e.data.localRef}'.
              Do you want to force the checkout?
            </DecisionModal>
        )
      default:
        return <div style={{color: "red"}}>{"" + e}</div>
    }
  } else {
    return <div style={{color: "red"}}>{"" + e}</div>
  }
}

interface NewBranchModalArgs {
  onCancel: VoidFunction
  projectName: string
  sourceBranch: GitBranch
}

function NewBranchModal({onCancel, projectName, sourceBranch}: NewBranchModalArgs) {
  const [newBranchName, setNewBranchName] = useState<string>()
  const api = useApi<Api>()
  const navigate = useNavigate()
  const {setInitialState} = useContext(InitialStateContext)

  return (
      <Modal onCancel={onCancel}>
        <Field
            title={"New branch name"}
            description={"Name of new branch to checkout"}
            value={newBranchName}
            onChange={(e: FormEvent<HTMLInputElement>) => {setNewBranchName(e.currentTarget.value)}}
        />
        <LoadingButton
            onClick={async () => {
              if(newBranchName) {
                await api.createNewBranch(projectName, sourceBranch, newBranchName)
                setInitialState(await api.getInitialState())
                navigate("/state")
              }
            }}
            disabled={!newBranchName}
        >
          Create branch '{newBranchName}' from '{sourceBranch.branchName}'.
        </LoadingButton>
      </Modal>
  )
}

interface BranchViewerContentsArgs {
  projectName: string,
  branches: GitBranch[]
}
function BranchViewerContents({projectName, branches}: BranchViewerContentsArgs) {
  return (
      <Grid>
        <Section size={6}>
          <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
            <Typography as="h2" format="tl" weight="bold">Branches</Typography>
            {branches.map(x=><BranchLine projectName={projectName} branch={x} />) }
          </div>
        </Section>
        <Section size={6} >
          <Typography as="h2" format="tl" weight="bold">Create PR</Typography>
          <CreatePullRequest projectName={projectName} branches={branches}/>
        </Section>
      </Grid>
  )
}

interface BranchViewerArgs {
  projectName: string
}
export default function BranchViewer({projectName}: BranchViewerArgs) {
  let api = useApi<Api>()
  let getBranches = useCallback(()=>api.listBranches(projectName), [projectName, api])
  return <WithPromise promise={getBranches} onSuccess={(d)=><BranchViewerContents projectName={projectName}  branches={d} />} />
}