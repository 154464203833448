import {useCallback, useState} from "react";
import {GitProject} from "../Project";
import {WithPromise} from "../../util/Loader";
import NewProject from "./editor/NewProject";
import ProjectGrid from "./grid/ProjectGrid";
import {ProjectListContext} from "./ProjectListContext";
import {EditModal} from "./editor/EditProject";
import {Api} from "../../api/Api";
import {useApi} from "@matillion/octo-react-util";

interface ProjectListContentArgs {
  projects: GitProject[],
}
export function ProjectListContent({projects}: ProjectListContentArgs) {
  let [editing, setEditing] = useState<GitProject | null>(null)
  let close = useCallback(()=>setEditing(null), [])
  return <div>
    <ProjectGrid projects={projects} setEditing={setEditing}/>
    <NewProject/>
    {editing ? <EditModal project={editing} close={close} /> : null }
  </div>
}

export default function ProjectList() {
  let api = useApi<Api>()
  let promise = useCallback(()=>api.getInitialState(), [api])
  return <WithPromise
    promise={promise}
    onSuccess={(initialState, extra)=><ProjectListContext.Provider value={{...extra}}>
        <ProjectListContent projects={initialState.projects}/>
      </ProjectListContext.Provider>
    }
  />
}