import {MfeContainer, useAppProps} from "@matillion/octo-react-util";

const PipelineBrowserMfe = () => {
    const {serviceLocationConfig} = useAppProps()
    return <MfeContainer
        hostname={serviceLocationConfig.pipelineServiceConsoleLocation}
        name={"PipelineBrowserMfe"}
        mfeProps={{baseRoute: "pipelines"}}
    />
}

export default PipelineBrowserMfe