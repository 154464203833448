export class CustomApiError extends Error {
    constructor(message: string, errorType: CustomErrorType, data: any) {
        super(message)
        this.errorType = errorType
        this.data = data
    }
    errorType: CustomErrorType
    data: any
}

export enum CustomErrorType {
    UNCOMMITTED_CHANGES = "UNCOMMITTED_CHANGES" as any,
    UNEXPECTED_REMOTE_STATE = "UNEXPECTED_REMOTE_STATE" as any,
    GENERIC = "GENERIC" as any,
}