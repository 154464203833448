import {Setter} from "../../../util/Setter";
import {useCallback, useState} from "react";
import {Field} from "@matillion/component-library";

export type Validator = (value: string)=>(string | undefined)
const NullValidator: Validator = ()=>undefined
export interface FieldData {
  id: string,
  title: string,
  description?: string,
  disabled?: boolean
  validation?: Validator
}

interface MyFieldArgs<T extends object> {
  field: FieldData,
  obj: T,
  setObj: Setter<T>
}
export function MyField<T extends object>({field, obj, setObj}: MyFieldArgs<T>) {
  let [error, setError] = useState<string | undefined>(undefined)
  let value = (obj as any)[field.id] as string
  let callback = useCallback((e)=>setObj(x=>({...x, [field.id]: e.target.value})), [field, setObj])
  let onBlur = useCallback(()=>{
    let validator: Validator = field.validation ?? NullValidator
    setError(validator(value))
  }, [value, field, setError])
  return <Field title={field.title}
                description={field.description}
                disabled={field.disabled}
                value={value}
                errorText={error}
                onBlur={onBlur}
                onChange={callback}
  />
}