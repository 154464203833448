import {AuthDetails} from "../../Project";
import {Label} from "@matillion/component-library";
import {Setter, useSubSetter} from "../../../util/Setter";
import {MyField} from "../editor/MyField";
import {MfeContainer, useAppProps} from "@matillion/octo-react-util";

interface AuthSetupArgs {
  auth: AuthDetails,
  setAuth: Setter<AuthDetails>
}
export default function AuthSetup({auth, setAuth}: AuthSetupArgs) {
  const {serviceLocationConfig: urls} = useAppProps()

  let setPassword = useSubSetter<AuthDetails, string>(setAuth, "passwordId")
  return <div>
    <MyField field={{title: "Username", id: "username"}} obj={auth} setObj={setAuth} />
    <Label title="Password">
      <MfeContainer
          hostname={urls.secretServiceConsoleLocation}
          name={"SecretSelector"}
          mfeProps={{secretName: auth.passwordId, setSecretName: setPassword}}
      />
    </Label>
  </div>
}