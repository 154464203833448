import {Setter} from "../../util/Setter";
import {useCallback} from "react";
import ChangeSelector, {Selector} from "./ChangeSelector";
import {DataGrid} from "@matillion/component-library";

interface AllChangeViewerArgs {
  changes: Change[],
  selected: string[],
  setSelected: Setter<string[]>
}

export function AllChangeViewer({changes, selected, setSelected}: AllChangeViewerArgs) {
  let toggle = useCallback((path: string, newValue: boolean) => {
    setSelected((old) => {
      if (newValue) {
        return [...old, path]
      } else {
        return old.filter(x => x !== path)
      }
    })
  }, [setSelected])
  let decorated = changes.map(x => {
    let selector: Selector = {selected: selected.includes(x.filePath), path: x.filePath, toggleSelected: toggle}
    return {...x, id: x.filePath, selector}
  })
  return <DataGrid columns={[
    {
      key: "filePath",
      title: "Path"
    },
    {
      key: "type",
      title: "Change Type"
    },
    {
      key: "selector",
      title: "",
      as: ChangeSelector
    }
  ]} rows={decorated}/>
}