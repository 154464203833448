import {Dispatch, SetStateAction, useCallback} from "react";

export type Setter<X> = Dispatch<SetStateAction<X>>

function update<T extends NotFunction>(oldOne: T, newValue: Function | T) {
  return typeof(newValue) === "function" ? newValue(oldOne) : newValue
}

export function useDecoratedSetter<T extends NotFunction>(setter: Setter<T>, extra: (newT: T)=>void): Setter<T> {
  return useCallback((newValue) => {
    setter(oldOne=>{
      let newX = update(oldOne, newValue)
      extra(newX)
      return newX
    })
  }, [setter, extra])
}

type NotFunction = object | string | number | boolean
export function subSetter<T extends object, U extends NotFunction>(setter: Setter<T>, field: string): Setter<U> {
  return (newValue) => {
    setter(oldOne=>{
      let old = (oldOne as any)[field] as U
      let newX = update(old, newValue)
      return {
        ...oldOne,
        [field]: newX
      }
    })
  }
}

export function useSubSetter<T extends object, U extends NotFunction>(setter: Setter<T>, field: string): Setter<U> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(subSetter(setter, field), [setter, field])
}