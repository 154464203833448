import {AuthDetails, GitProject} from "../../Project";
import {Setter} from "../../../util/Setter";
import {useCallback, useMemo} from "react";
import {FieldData, MyField} from "./MyField";
import AuthSetup from "../auth/AuthSetup";
import TestButton from "./TestButton";

let fields: FieldData[] = [
  {
    id: "url",
    title: "Git Repo URL"
  },
  {
    id: "jobPath",
    title: "METL Job Path"
  }
]

interface ProjectDetailsEditorArgs {
  project: GitProject,
  setProject: Setter<GitProject>
}
export default function ProjectDetailsEditor({project, setProject}: ProjectDetailsEditorArgs) {
  let setAuth: Setter<AuthDetails> = useCallback((q)=>setProject(x=>({...x, authDetails: typeof(q) === "function" ? q(x.authDetails) : q})), [setProject])
  const auth = useMemo(() => {
    return <AuthSetup auth={project.authDetails} setAuth={setAuth} />
  }, [project.authDetails, setAuth])
  return <>
    {fields.map(x=><MyField field={x} key={x.id} obj={project} setObj={setProject} />)}
    {auth}
    <TestButton project={project} />
  </>
}