import {GitProject, InitialState} from "../project/Project";
import {SecretPackage} from "../project/list/auth/SecretPackage";
import {GitBranch} from "../project/viewer/Branches";
import {GitActions} from "./actions/GitActions";
import {BaseApi} from "@matillion/octo-react-util";
import {CustomApiError} from "./CustomApiError";
import {PullRequest} from "../project/viewer/CreatePullRequest";

export class Api extends BaseApi{
  public readonly git = new GitActions(this)

  async getInitialState(): Promise<InitialState> {
    return this.get("/api/git/state")
  }

  async getPasswords(): Promise<SecretPackage[]> {
    return this.get("/api/secrets")
  }

  async saveProject(project: GitProject) {
    await this.post("/api/git/set", project)
  }

  async testProject(project: GitProject) {
    await this.post("/api/git/test", project)
  }

  async listBranches(projectName: string): Promise<GitBranch[]> {
    return this.post("/api/git/listBranches", {projectName})
  }

  async getStatus(): Promise<Status> {
    return this.get("/api/git/status")
  }

  async checkout(projectName: string, branch: GitBranch, force: boolean = false) {
    const options = force ? [{checkoutOptionType: "f"}] : undefined
    await this.post(`/api/git/checkout`, {projectName, branch, options})
  }

  async createNewBranch(projectName: string, sourceBranch: GitBranch, newBranchName: string) {
    await this.post(
        `/api/git/checkout`,
        {projectName, branch: sourceBranch, options: [{checkoutOptionType: "b", newBranchName}]}
    )
  }

  async commit(commitMessage: string, changes: Change[], username: string) {
    await this.post("/api/git/commit", {commitMessage, changes, username})
  }

  async putPullRequest(pullRequest: PullRequest) {
    await this.put(`/api/git/pullRequests`, pullRequest)
  }

  async getPullRequest(projectName: string, pullRequestName: string): Promise<PullRequest> {
    return this.get(`/api/git/project/${projectName}/pullRequests/${pullRequestName}`)
  }

  async getAllPullRequests(projectName: string | undefined = undefined): Promise<PullRequest[]> {
    return this.get(projectName ? `/api/git/project/${projectName}/pullRequests` : `/api/git/pullRequests`)
  }

  async mergePullRequest(projectName: string, pullRequestName: string): Promise<PullRequest> {
    return this.post(`/api/git/project/${projectName}/pullRequests/${pullRequestName}`, undefined)
  }

  override async handleBadResponse(response: Response): Promise<never> {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      let json = await response.json()
      if(json.hasOwnProperty("message") && json.hasOwnProperty("errorType") && json.hasOwnProperty("data")) {
        let message = json.message
        // @ts-ignore
        let errorType = json.errorType
        let data = json.data
        throw new CustomApiError(message, errorType, data)
      }else
        throw Error("Error with request ["+response.status+"]: " + JSON.stringify(json))
    } else
      throw Error("Error with request ["+response.status+"]: " + await response.text())
  }
}