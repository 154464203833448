import {GitProject} from "../../Project";
import {DataGrid} from "@matillion/component-library";
import {GitProjectActionData, ProjectActions} from "./ProjectActions";

const columns = [
  {
    key: "name",
    title: "Project Name"
  },
  {
    key: "url",
    title: "Git URL"
  },
  {
    key: "instanceType",
    title: "Type",
    as: (t: any)=><pre>{t.environmentType} - {t.platformType}</pre>
  },
  {
    key: "webhookId",
    title: "Webhook ID"
  },
  {
    key: "actions",
    title: "",
    as: ProjectActions
  }
]

interface IDGP extends GitProject {
  id: string,
  actions: GitProjectActionData
}
interface ProjectGridArgs {
  projects: GitProject[],
  setEditing: (project: GitProject) => void
}
export default function ProjectGrid({projects, setEditing}: ProjectGridArgs) {
  let idProjects: IDGP[] = projects.map(x=>({...x, id: x.name, actions: {project: x, edit: setEditing}, webhookId: x.webhookId}))
  return <DataGrid columns={columns} rows={idProjects} />
}