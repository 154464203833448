import React, {ReactNode, useCallback, useState} from "react";
import {Button} from "@matillion/component-library";

interface LoadingButtonArgs {
  children?: ReactNode,
  onClick: ()=>Promise<any>
  onError?: (error: Error) => void
  disabled?: boolean
}
export default function LoadingButton({children, onClick, onError, disabled = false}: LoadingButtonArgs) {
  let [loading, setLoading] = useState(false)
  let [error, setError] = useState<string>("")
  let click = useCallback(async ()=>{
    setError("")
    setLoading(true)
    try {
      await onClick()
    } catch(e) {
      if(onError && e instanceof Error) onError(e)
      else setError("" + e)
    }
    setLoading(false)
  }, [onClick, onError])

  return <>
    <Button onClick={click} waiting={loading} disabled={disabled}>
      {children}
    </Button>
    <div style={{color: "red"}}>{error}</div>
  </>
}