import React from 'react';
import './App.css';
import AppRouter from "./ui/app/AppRouter";
import {Api} from "./api/Api";
import {Auth0ContextInterface} from "@auth0/auth0-react";
import {AppBase} from "@matillion/octo-react-util";

function App() {
    return (
        <AppBase
            applicationName={'Designer Git'}
            apiProducer={(auth0: Auth0ContextInterface, orgId: string) => new Api(auth0, orgId) }
            appPropsLocations={[{name: "serviceLocationConfig", endpoint: "api/serviceLocationConfig"}]}
        >
            <AppRouter />
        </AppBase>
    );
}

export default App;
