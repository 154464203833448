import {LoadingSpinner, SidebarLayout} from "@matillion/component-library";
import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {usePromise} from "../../util/Loader";
import {InitialState} from "../../project/Project";
import Sidebar from "./Sidebar";
import {Api} from "../../api/Api";
import {useApi} from "@matillion/octo-react-util";

export const InitialStateContext = createContext<{
  initialState: InitialState,
  setInitialState: (initialState: InitialState) => void
}>({initialState: {projects: []}, setInitialState: () => {}})

export function InitialIndexRouter() {
  let navigate = useNavigate()
  let result = useContext(InitialStateContext)
  useEffect(()=>{
    if(result.initialState) {
      if(result.initialState.workingTreeState) {
        navigate("state")
      } else {
        navigate("projects")
      }
    }
  }, [navigate, result])
  return <LoadingSpinner />
}

export default function InitialPage() {
  let api = useApi<Api>()
  let get = useCallback(()=>api.getInitialState(), [api])
  let {result, loading, error, refresh} = usePromise(get, [api])
  let [initialState, setInitialState] = useState<InitialState>()
  useEffect(() => setInitialState(result), [result, setInitialState])
  if(error) {
    return <div>
      {error}
      <button onClick={refresh}>Refresh</button>
    </div>
  } else if(loading || !initialState) {
    return <LoadingSpinner/>
  } else {
    return <InitialStateContext.Provider value={{initialState, setInitialState}}>
      <SidebarLayout sidebar={<Sidebar />}>
        <Outlet />
      </SidebarLayout>
    </InitialStateContext.Provider>
  }
}