import {GitProject} from "../../Project";
import {useCallback, useContext, useEffect, useState} from "react";
import {MyField} from "./MyField";
import {ProjectNameField} from "./NewProject";
import ProjectDetailsEditor from "./ProjectDetailsEditor";
import {ProjectListContext} from "../ProjectListContext";
import {Button, Modal} from "@matillion/component-library";
import {Api} from "../../../api/Api";
import {useApi} from "@matillion/octo-react-util";

interface EditProjectArgs {
  project: GitProject,
  save: (newProject: GitProject)=>Promise<void>
}
export function EditProject({project: original, save}: EditProjectArgs) {
  let [project, setProject] = useState(original)
  useEffect(()=>setProject(original), [original, setProject])
  let saveMe = useCallback(()=>save(project), [save, project])
  return <div>
    <MyField field={{...ProjectNameField, disabled: true}} obj={project} setObj={setProject} />
    <ProjectDetailsEditor project={project} setProject={setProject} />
    <Button onClick={saveMe}>Save</Button>
  </div>
}

interface EditModalArgs {
  project: GitProject,
  close: ()=>void
}
export function EditModal({project, close}: EditModalArgs) {
  let api = useApi<Api>()
  let {refresh} = useContext(ProjectListContext)
  let save = useCallback(async (newProject: GitProject)=>{
    await api.saveProject(newProject)
    close()
    refresh()
  }, [refresh, api, close])
  return <Modal onCancel={close}>
    <EditProject project={project} save={save} />
  </Modal>
}