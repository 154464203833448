import {useEffect, useState} from "react";
import {PullRequest} from "./CreatePullRequest";
import {useApi} from "@matillion/octo-react-util";
import {Api} from "../../api/Api";
import {Link} from "react-router-dom";
import {Button, DataGrid, LoadingSpinner} from "@matillion/component-library";
import YesNoIcon from "./YesNoIcon";
import LatestPipelineStateIconMfe from "../../ui/microFrontend/LatestPipelineStateIconMfe";

const PullRequestListViewer = () => {
    const [pullRequests, setPullRequests] = useState<PullRequest[]>()
    const api = useApi<Api>()

    useEffect(() => {
        async function fetchPullRequests() {
            setPullRequests(await api.getAllPullRequests())
        }
        fetchPullRequests()
    }, [setPullRequests, api])

    if(!pullRequests) return <LoadingSpinner/>
    return (
        <DataGrid
            columns={[
                {
                    key: 'project',
                    title: 'Project'
                },
                {
                    key: 'name',
                    title: 'Name'
                },
                {
                    key: 'sourceBranch',
                    title: 'Source Branch'
                },
                {
                    key: 'pipeline',
                    title: 'Source Branch Pipeline',
                    as: LatestPipelineStateIconMfe,
                },
                {
                    key: 'targetBranch',
                    title: 'Target Branch'
                },
                {
                    key: 'approved',
                    title: 'approved',
                    as: YesNoIcon,
                },
                {
                    key: 'merged',
                    title: 'Merged',
                    as: YesNoIcon,
                },
                {
                    key: 'details',
                    title: '',
                    as: Link,
                },
            ]}
            rows={pullRequests.map((pr) => ({
                id: pr.projectName+pr.name,
                project: pr.projectName,
                name: pr.name,
                sourceBranch: pr.sourceBranch,
                pipeline: {
                    projectName: pr.projectName,
                    branchName: pr.sourceBranch,
                },
                targetBranch: pr.targetBranch,
                approved: {
                    status: pr.approved ? "yes" : "no"
                },
                merged: {
                    status: pr.merged ? "yes" : "no"
                },
                details: {
                    children: <Button>Details</Button>,
                    to: `/projects/${pr.projectName}/pullRequests/${pr.name}`,
                }
            }))}
        />
    )
}

export default PullRequestListViewer