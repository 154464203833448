import {Icon} from "@matillion/component-library";

interface YesNoIconProps {
    status: "yes" | "no"
}

const YesNoIcon = ({status}: YesNoIconProps) => {
    if(status === "yes") return <Icon.Success/>
    else return <Icon.Minus/>
}

export default YesNoIcon