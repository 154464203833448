import {AutoComplete, Button, Input, Label, Textarea} from "@matillion/component-library";
import React, {ChangeEvent, useState} from "react";
import {GitBranch} from "./Branches";
import {useApi} from "@matillion/octo-react-util";
import {Api} from "../../api/Api";
import {useNavigate} from "react-router-dom";

export interface PullRequest {
    projectName: string,
    name: string,
    description: string,
    sourceBranch: string,
    targetBranch: string,
    approved: boolean,
    comments: string[],
    merged: boolean,
}

interface CreatePullRequestProps {
    projectName: string
    branches: GitBranch[]
}

const CreatePullRequest = ({projectName, branches}: CreatePullRequestProps) => {
    const [name, setName] = useState<string>()
    const [description, setDescription] = useState<string>()
    const [sourceBranch, setSourceBranch] = useState<string>()
    const [targetBranch, setTargetBranch] = useState<string>()
    const branchAutoCompleteItems = branches.map(({branchName}) => ({id: branchName, name: branchName}))
    const api = useApi<Api>()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    return (
        <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
            <Label title="Pull Request Name">
                <Input
                    value={name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
                />
            </Label>
            <Label title="Pull Request Description">
                <Textarea
                    value={description}
                    onChange={(e) => setDescription(e.currentTarget.value)}
                />
            </Label>
            <Label title="Source Branch" description={"The branch containing the changes to be merged in."}>
                <AutoComplete
                    availableItems={branchAutoCompleteItems}
                    onChange={(e) => setSourceBranch(e.target.value?.name)}
                />
            </Label>
            <Label title="Target Branch" description={"The branch that will have the changes merged into."}>
                <AutoComplete
                    availableItems={branchAutoCompleteItems}
                    onChange={(e) => setTargetBranch(e.target.value?.name)}
                />
            </Label>
            <Button
                text={"Create Pull Request"}
                disabled={!name || ! description || !sourceBranch || !targetBranch}
                waiting={loading}
                onClick={async () => {
                    if(name && description && sourceBranch && targetBranch) {
                        setLoading(true)
                        await api.putPullRequest({
                            projectName,
                            name,
                            description,
                            sourceBranch,
                            targetBranch,
                            approved: false,
                            comments: [],
                            merged: false
                        })
                        navigate(`/projects/${projectName}/pullRequests/${name}`)
                    }
                }}
            />
        </div>
    )
}

export default CreatePullRequest