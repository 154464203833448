import {AllChangeViewer} from "./AllChangeViewer";
import {useMemo, useState} from "react";
import {CommitPart} from "./CommitPart";

interface ChangesViewerArgs {
  changes: Changes,
  refresh: ()=>void
}
export default function ChangesViewer({changes, refresh}: ChangesViewerArgs) {
  let [selected, setSelected] = useState<string[]>([])
  let allChanges = useMemo(()=>[...changes.dotfileChanges, ...changes.nonDotfileChanges], [changes])
  if(allChanges.length > 0) {
    return <div>
      <AllChangeViewer changes={allChanges} selected={selected} setSelected={setSelected}/>
      <CommitPart selected={selected} changes={allChanges} refresh={refresh}/>
    </div>
  } else {
    return <div>
      No changes detected
    </div>
  }
}