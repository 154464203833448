import {useCallback, useContext} from "react";
import {InitialStateContext} from "../ui/app/InitialPage";
import ChangesViewer from "./commit/ChangesViewer";
import {WithPromise} from "../util/Loader";
import {Link} from "react-router-dom";
import {Button, Card, Typography} from "@matillion/component-library";
import Styles from "./StateScreen.module.scss"
import IconSchema from "./Icon_Schema.png"
import Refresh from "./commit/refresh.svg"
import {Api} from "../api/Api";
import {useApi, useAppProps, useOrgId} from "@matillion/octo-react-util";
import LatestPipelineStateIconMfe from "../ui/microFrontend/LatestPipelineStateIconMfe";

function IconEtl() {
  return <svg width="80" height="80" viewBox="0 0 80 80" fill="none">
    <path d="M22 24H57V28H22V24Z" fill="#DFEDD3" />
    <path d="M22 53H33.8766C34.5936 53 35.2558 52.6162 35.6121 51.994L50.5 26" stroke="#DFEDD3" stroke-width="4" />
    <path d="M22 51H24C25.1046 51 26 51.8954 26 53C26 54.1046 25.1046 55 24 55H22V51Z" fill="#2D8825" />
    <path d="M30 53C30 51.8954 30.8954 51 32 51C33.1046 51 34 51.8954 34 53C34 54.1046 33.1046 55 32 55C30.8954 55 30 54.1046 30 53Z" fill="#2D8825" />
    <path d="M47.7206 31.0939C48.6772 31.6462 49.0049 32.8694 48.4526 33.826C47.9004 34.7826 46.6772 35.1103 45.7206 34.558C44.764 34.0058 44.4363 32.7826 44.9885 31.826C45.5408 30.8694 46.764 30.5416 47.7206 31.0939Z" fill="#2D8825" />
    <path d="M43.1342 39.0386C44.0907 39.5908 44.4185 40.814 43.8663 41.7706C43.314 42.7271 42.0908 43.0548 41.1342 42.5025C40.1777 41.9503 39.8499 40.7271 40.4021 39.7706C40.9544 38.814 42.1776 38.4863 43.1342 39.0386Z" fill="#2D8825" />
    <path d="M38.808 46.531C39.7646 47.0833 40.0923 48.3065 39.54 49.2631C38.9878 50.2196 37.7646 50.5474 36.808 49.9951C35.8514 49.4428 35.5237 48.2196 36.0759 47.2631C36.6282 46.3065 37.8514 45.9787 38.808 46.531Z" fill="#2D8825" />
    <rect y="43" width="20" height="20" fill="#2D8825" />
    <rect y="16" width="20" height="20" fill="#2D8825" />
    <rect x="59" y="16" width="20" height="20" fill="#51A620" />
    <path d="M22 24H24C25.1046 24 26 24.8954 26 26C26 27.1046 25.1046 28 24 28H22V24Z" fill="#2D8825" />
    <path d="M57 28L55 28C53.8954 28 53 27.1046 53 26C53 24.8954 53.8954 24 55 24L57 24L57 28Z" fill="#2D8825"/>
    <path d="M29 26C29 24.8954 29.8954 24 31 24C32.1046 24 33 24.8954 33 26C33 27.1046 32.1046 28 31 28C29.8954 28 29 27.1046 29 26Z" fill="#2D8825" />
    <path d="M37 26C37 24.8954 37.8954 24 39 24C40.1046 24 41 24.8954 41 26C41 27.1046 40.1046 28 39 28C37.8954 28 37 27.1046 37 26Z" fill="#2D8825" />
    <path d="M45 26C45 24.8954 45.8954 24 47 24C48.1046 24 49 24.8954 49 26C49 27.1046 48.1046 28 47 28C45.8954 28 45 27.1046 45 26Z" fill="#2D8825" />
  </svg>
}

interface StatusViewerArgs {
  status: Status,
  refresh: ()=>void
}
function StatusViewer({status, refresh}: StatusViewerArgs) {
  return <div>
    <div style={{display: "flex", flexDirection: "row"}} className={Styles.cheat}>
      <Typography format="ts" weight="bold" style={{width: "130px"}}>Changes:</Typography>
      <Button icon={Refresh} onClick={refresh} />
    </div>
    <ChangesViewer changes={status.changes} refresh={refresh} />
  </div>
}

export default function StateScreen() {
  let {initialState: {workingTreeState}} = useContext(InitialStateContext)
  let api = useApi<Api>()
  let getStatus = useCallback(()=>api.getStatus(), [api])
  let {serviceLocationConfig: urls} = useAppProps()
  let orgId = useOrgId()
  let openDesigner = useCallback(()=>window.open(`${urls.designerUiLocation}?orgId=${orgId}`, "_blank"), [orgId, urls.designerUiLocation])
  let openDbFab = useCallback(() => window.open(`${urls.dbFabUiLocation}?orgId=${orgId}`, "_blank"), [orgId, urls.dbFabUiLocation])
  return <div style={{padding: "20px"}}>
    <div className={Styles.stateBanner}>
      <div style={{padding: "10px"}}>
        <div className={Styles.stateBannerField}>
          <Typography as="h2" format="ts" weight="bold">Project:</Typography>
        </div>
        <Link to={"/projects/"+workingTreeState?.projectName}>
          <Typography as="h2" format="ts" >{workingTreeState?.projectName}</Typography>
        </Link>
      </div>
      <div style={{padding: "10px"}}>
        <div className={Styles.stateBannerField}>
          <Typography as="h2" format="ts" weight="bold">Current Branch:</Typography>
        </div>
        <Typography as="h2" format="ts">
          {workingTreeState?.branch.branchName}
        </Typography>
      </div>
      <div style={{padding: "10px"}}>
        <div className={Styles.stateBannerField}>
          <Typography as="h2" format="ts" weight="bold">Latest Pipeline:</Typography>
        </div>
        {workingTreeState ? <LatestPipelineStateIconMfe
            projectName={workingTreeState?.projectName.toString()}
            branchName={workingTreeState?.branch.branchName}
        /> : null}
      </div>
    </div>
    <div className={Styles.buttonContainer}>
      <Card onClick={openDesigner}>
        <div className={Styles.buttonBox} >
          <IconEtl />
          <Typography format="tm">Open Designer</Typography>
          <Typography format="bcm">Use ETL Designer to build ETL Orchestration and Transformation pipelines</Typography>
        </div>
      </Card>
      <Card onClick={openDbFab}>
        <div className={Styles.buttonBox} >
          <img src={IconSchema} alt="Schema Editor"/>
          <Typography format="tm">Open Schema Editor</Typography>
          <Typography format="bcm">Use Schema Designer to create and manager your cloud data warehouse schemas</Typography>
        </div>
      </Card>
    </div>
    <div>
      <WithPromise promise={getStatus} onSuccess={(c, e)=><StatusViewer status={c} refresh={e.refresh}/>} />
    </div>
  </div>
}