import {GitProject} from "../Project";
import BranchViewer from "./BranchViewer";
import {useCallback, useContext} from "react";
import {InitialStateContext} from "../../ui/app/InitialPage";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Grid, Section, Typography} from "@matillion/component-library";

interface GitProjectViewerArgs {
  project: GitProject
  close: ()=>void
}
function GitProjectViewer({project}: GitProjectViewerArgs) {
  return (
      <Grid>
        <Section><Typography as="h1" format="dts" weight="bold">PROJECT {project.name}</Typography></Section>
        <Section><BranchViewer projectName={project.name} /></Section>
      </Grid>
  )
}

export function GitProjectThingViewer() {
  let {projectName} = useParams()
  let {initialState: {projects}} = useContext(InitialStateContext)
  let navigate = useNavigate()
  let project = projects.find(x=>x.name === projectName)
  let close = useCallback(()=>navigate("/projects"), [navigate])
  if(project)
    return <GitProjectViewer project={project} close={close} />
  else
    return <div>
      Project [{projectName}] not found
      <Button onClick={close}>Go back</Button>
    </div>
}