import {useCallback} from "react";

export interface Selector {
  selected: boolean,
  path: string,
  toggleSelected: (path: string, newValue: boolean)=>void
}

export default function ChangeSelector({selected, path, toggleSelected}: Selector) {
  let toggle = useCallback((e)=>{
    toggleSelected(path, !selected)
    console.log("ASD")
  }, [selected, path, toggleSelected])
  return <input type="checkbox" checked={selected} onChange={toggle} />
}