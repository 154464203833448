import {Button, Modal, Typography} from "@matillion/component-library";
import React from "react";

interface DecisionModalProps {
    title: string,
    children?: React.ReactNode,
    yesAction?: () => void,
    noAction?: () => void,
    closeModal: () => void,
}


export const DecisionModal = (
    {
        title,
        children,
        yesAction,
        closeModal,
        noAction = closeModal,
    }: DecisionModalProps
) => {
    return (
        <Modal
            onCancel={closeModal}
            size={"large"}
        >
            <Typography as="h2" format="ts" weight="bold">{title}</Typography>
            {children}
            <div style={{display: "flex", flexDirection: "row"}}>
                <Button onClick={yesAction}>Yes</Button>
                <Button onClick={noAction}>No</Button>
            </div>
        </Modal>
    )
}