import {useCallback, useContext, useState} from "react";
import {GitProject} from "../../Project";
import {Button, Modal} from "@matillion/component-library";
import {FieldData, MyField} from "./MyField";
import ProjectDetailsEditor from "./ProjectDetailsEditor";
import {ProjectListContext} from "../ProjectListContext";
import {Api} from "../../../api/Api";
import {useApi} from "@matillion/octo-react-util";

export const InitialGitProject: GitProject = {
  name: "",
  url: "",
  jobPath: "",
  authDetails: {
    username: "",
    passwordId: ""
  },
  instanceType: {
    platformType: "AWS",
    environmentType: "SNOWFLAKE"
  },
  webhookId: ""
}

export const ProjectNameField: FieldData = {
  id: "name",
  title: "Project Name",
  validation: value => {
    if(value.length < 3) return "Name must be longer than 3 characters"
  }
}

interface NewProjectModalArgs {
  close: ()=>void,
}
function NewProjectModal({close}: NewProjectModalArgs) {
  let api = useApi<Api>()
  let {refresh} = useContext(ProjectListContext)
  let [project, setProject] = useState<GitProject>(InitialGitProject)
  let [saving, setSaving] = useState(false)
  let [error, setError] = useState("")
  let save = useCallback(async ()=>{
    setSaving(true)
    setError("")
    try {
      await api.saveProject(project)
      close()
      refresh()
    } catch(e) {
      setError("" + e)
    }
    setSaving(false)
  }, [api, project, close, refresh])
  return <Modal onCancel={close}>
    <div>
      <MyField field={ProjectNameField} obj={project} setObj={setProject} />
      <ProjectDetailsEditor project={project} setProject={setProject} />
      <Button onClick={save} text="Save" waiting={saving}/>
      <div style={{color: "red"}}>{error}</div>
    </div>
  </Modal>
}

export default function NewProject() {
  let [isOpen, setIsOpen] = useState(false)
  let open = useCallback(()=>setIsOpen(true), [])
  let close = useCallback(()=>setIsOpen(false), [])
  return <div style={{marginTop: "20px", marginRight: "7px", float: "right"}}>
    <Button onClick={open}>New Project</Button>
    {isOpen ? <NewProjectModal close={close} /> : null }
  </div>
}