import {SidebarLayout, SidebarMenu, SidebarMenuItem} from "@matillion/component-library";
import {Link, Outlet, useMatch} from "react-router-dom";
import {useContext} from "react";
import {InitialStateContext} from "./InitialPage";

export function ProjectsItem() {
  let match = useMatch("/projects/*") !== null
  return <SidebarMenuItem active={match}>
    <Link to={"/projects"}>Projects</Link>
  </SidebarMenuItem>
}

export function StateItem() {
  let {initialState: {workingTreeState}} = useContext(InitialStateContext)
  let match = useMatch("/state") !== null
  if(workingTreeState) {
    return <SidebarMenuItem active={match}>
      <Link to={"/state"}>Current Workspace</Link>
    </SidebarMenuItem>
  } else {
    return null
  }
}

export function PipelineItem() {
  let match = useMatch("/pipelines") !== null
  return <SidebarMenuItem active={match}>
    <Link to={"/pipelines"}>Pipelines</Link>
  </SidebarMenuItem>
}

export function PullRequestItem() {
  let match = useMatch("/pullRequests") !== null
  return <SidebarMenuItem active={match}>
    <Link to={"/pullRequests"}>Pull Requests</Link>
  </SidebarMenuItem>
}

export default function Sidebar() {
  return <SidebarMenu>
    <div style={{paddingLeft: "10px", paddingTop: "15px"}}>
      <ProjectsItem />
      <StateItem />
      <PipelineItem />
      <PullRequestItem />
    </div>
  </SidebarMenu>
}

export function SidebarPage() {
  return <SidebarLayout sidebar={<Sidebar />}>
    <Outlet />
  </SidebarLayout>
}