import {MfeContainer, useAppProps} from "@matillion/octo-react-util";

interface LatestPipelineStateIconMfeProps {
    projectName: string,
    branchName: string,
}

const LatestPipelineStateIconMfe = (props: LatestPipelineStateIconMfeProps) => {
    const {serviceLocationConfig} = useAppProps()
    return (
        <MfeContainer
            hostname={serviceLocationConfig.pipelineServiceConsoleLocation}
            name={"LatestPipelineStateIconMfe"}
            mfeProps={{...props, baseRoute: "/pipelines"}}
        />
    )
}

export default LatestPipelineStateIconMfe