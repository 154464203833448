import {DependencyList, ReactElement, useCallback, useEffect, useState} from "react";
import {LoadingSpinner} from "@matillion/component-library";

type PromiseGenerator<T> = ()=>Promise<T>
export interface LoadResultState<T> {
  loading: boolean,
  error?: string
  result?: T
}

export interface LoadResult<T> extends LoadResultState<T> {
  refresh: ()=>void
}

export function useAsync<T>(promise: PromiseGenerator<T>, dependencies: DependencyList = []): LoadResult<T> {
  let [state, setState] = useState<LoadResultState<T>>({loading: false})
  let refresh = useCallback(()=>{
    setState({loading: true})
    promise()
      .then(r=>{
        setState({loading: false, result: r})
      })
      .catch(e=>{
        setState({loading: false, error: e.toString()})
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promise, ...dependencies])
  return {...state, refresh}
}

export function usePromise<T>(promise: PromiseGenerator<T>, dependencies: DependencyList = []): LoadResult<T> {
  let result = useAsync(promise, dependencies)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(result.refresh, [result.refresh])
  return result
}

interface OnSuccessExtra {
  refresh: ()=>void
}
interface WithPromiseArgs<T> {
  promise: PromiseGenerator<T>,
  onSuccess: (t: T, extra: OnSuccessExtra)=>ReactElement
  dependencies?: DependencyList
}
export function WithPromise<T>({promise, onSuccess, dependencies = []}: WithPromiseArgs<T>) {
  let {loading, error, result, refresh} = usePromise(promise, dependencies)
  if(loading) {
    return <LoadingSpinner />
  } else {
    if(error) {
      return <div style={{whiteSpace: "pre-wrap", color: "red"}}>
        {error}
        <button onClick={refresh}>Retry</button>
      </div>
    } else if(result) {
      let extra = {
        refresh
      }
      return onSuccess(result, extra)
    } else {
      return <div style={{whiteSpace: "pre-wrap", color: "red"}}>
        SOMETHING WENT WRONG
      </div>
    }
  }
}