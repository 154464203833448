import {GitProject} from "../../Project";
import {useCallback} from "react";
import {Button} from "@matillion/component-library";
import {useNavigate} from "react-router-dom";

export interface GitProjectActionData {
  project: GitProject,
  edit: (project: GitProject)=>void
}
export function ProjectActions({project, edit}: GitProjectActionData) {
  let navigate = useNavigate()
  let editMe = useCallback(()=>edit(project), [project, edit])
  let openMe = useCallback(()=>navigate("/projects/" + project.name), [project, navigate])
  return <div style={{marginLeft: "auto"}}>
    <div style={{float: "right", marginLeft: "10px"}}><Button onClick={editMe}>Edit</Button></div>
    <div style={{float: "right"}}><Button onClick={openMe}>Open</Button></div>
  </div>
}