import {useCallback, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Button, Field} from "@matillion/component-library";
import {Api} from "../../api/Api";
import {useApi} from "@matillion/octo-react-util";

interface CommitPartArgs{
  selected: string[]
  changes: Change[],
  refresh: ()=>void
}
export function CommitPart({selected, changes, refresh}: CommitPartArgs) {
  let auth0 = useAuth0()
  let api = useApi<Api>()
  let selectedChanges = changes.filter(x=>selected.includes(x.filePath))
  let [message, setMessage] = useState("")
  let [username, setUsername] = useState(auth0.user?.name ?? "")
  let [loading, setLoading] = useState(false)
  let [error, setError] = useState("")
  let changeMessage = useCallback((e)=>setMessage(e.target.value), [setMessage])
  let changeUsername = useCallback((e)=>setUsername(e.target.value), [setUsername])
  let commit = useCallback(async ()=>{
    setError("")
    setLoading(true)
    try {
      await api.git.commit(message, selectedChanges, username)
      refresh()
    } catch(e: any) {
      setError(e.message)
    }
    setLoading(false)
  }, [message, selectedChanges, username, api, setLoading, refresh])
  let enabled = selected.length > 0 && changeMessage.length >0 && changeUsername.length > 0 && !loading
  return <>
    <Field title="Commit Message" value={message} onChange={changeMessage} />
    <Field title="Commit User" value={username} onChange={changeUsername} />
    <div style={{float: "right", marginTop: "10px"}}>
      <Button onClick={commit} disabled={!enabled}>Commit</Button>
    </div>
    {error}
  </>
}