import {GitProject} from "../../Project";
import {useAsync} from "../../../util/Loader";
import {Button} from "@matillion/component-library";
import {Api} from "../../../api/Api";
import {useApi} from "@matillion/octo-react-util";

interface TestButtonArgs {
  project: GitProject
}
export default function TestButton({project}: TestButtonArgs) {
  let api = useApi<Api>()
  let state = useAsync(()=>api.testProject(project))
  return <>
    <Button waiting={state.loading} onClick={state.refresh}>
      Test Project
    </Button>
    <div style={{color: "red"}} >{state.error}</div>
  </>
}