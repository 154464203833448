import {HashRouter, Route, Routes} from "react-router-dom";
import InitialPage, {InitialIndexRouter} from "./InitialPage"
import StateScreen from "../../state/StateScreen";
import {GitProjectThingViewer} from "../../project/viewer/GitProjectViewer";
import {SidebarPage} from "./Sidebar";
import ProjectList from "../../project/list/ProjectList";
import PipelineBrowserMfe from "../microFrontend/PipelineBrowserMfe";
import PullRequestViewer from "../../project/viewer/PullRequestViewer";
import PullRequestListViewer from "../../project/viewer/PullRequestListViewer";

export default function AppRouter() {
  return <HashRouter>
    <Routes>
      <Route path="/" element={<InitialPage />}>
        <Route element={<SidebarPage />} />
        <Route index element={<InitialIndexRouter />} />
        <Route path="projects" element={<ProjectList />} />
        <Route path="projects/:projectName" element={<GitProjectThingViewer />} />
        <Route path="state" element={<StateScreen />} />
        <Route path="pipelines/*" element={ <PipelineBrowserMfe/>} />
        <Route path="projects/:projectName/pullRequests/:pullRequestName" element={ <PullRequestViewer />} />
        <Route path="pullRequests" element={ <PullRequestListViewer />} />
      </Route>
    </Routes>
  </HashRouter>
}