import {
    Button,
    CollapsiblePanel,
    Grid,
    LoadingSpinner,
    Section,
    Textarea,
    Typography
} from "@matillion/component-library";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {PullRequest} from "./CreatePullRequest";
import {useApi} from "@matillion/octo-react-util";
import {Api} from "../../api/Api";
import {Checkbox} from "@mui/material";
import YesNoIcon from "./YesNoIcon";
import LatestPipelineStateIconMfe from "../../ui/microFrontend/LatestPipelineStateIconMfe";

const PullRequestViewer = () => {
    const {projectName, pullRequestName} = useParams()
    if (!projectName || !pullRequestName) throw Error("This component can only be used in the context of a router.")
    const [pullRequest, setPullRequest] = useState<PullRequest>()
    const api = useApi<Api>()
    const [newComment, setNewComment] = useState<string>()
    const [merging, setMerging] = useState(false)

    useEffect(() => {
        async function fetchPullRequest() {
            setPullRequest(await api.getPullRequest(projectName!, pullRequestName!))
        }
        fetchPullRequest()
    }, [api, projectName, pullRequestName])

    const updatePullRequest = (newPullRequest: PullRequest) => {
        api.putPullRequest(newPullRequest)
        setPullRequest(newPullRequest)
    }

    if (!pullRequest) return <LoadingSpinner/>
    return (
        <Grid>
            <Section><Typography as="h1" format="dts" weight="bold">Pull
                Request: {pullRequestName}</Typography></Section>
            <Section>Description: {pullRequest.description}</Section>
            <Section>
                Source Branch: {pullRequest.sourceBranch}
                <LatestPipelineStateIconMfe projectName={pullRequest.projectName} branchName={pullRequest.sourceBranch}/>
            </Section>
            <Section>Target Branch: {pullRequest.targetBranch}</Section>
            <Section>
                Approved:
                <Checkbox
                    checked={pullRequest.approved}
                    onChange={(e) => updatePullRequest({...pullRequest, approved: e.target.checked})}
                />
            </Section>
            <Section>
                <CollapsiblePanel subtitle="" title="Comments">
                    {pullRequest.comments.map((comment) =>
                        <Typography>{comment}</Typography>
                    )}
                    <Textarea
                        placeholder={"Comment..."}
                        value={newComment}
                        onChange={(e) => setNewComment(e.currentTarget.value)}
                    />
                    <Button
                        disabled={!newComment}
                        onClick={() => {
                            if (newComment)
                                updatePullRequest({...pullRequest, comments: [...pullRequest.comments, newComment]})
                        }}
                        text={"Submit Comment"}
                    />
                </CollapsiblePanel>
            </Section>
            <Section>
                {pullRequest.merged ?
                    <>Merged: <YesNoIcon status={"yes"} /></> :
                    <Button
                        waiting={merging}
                        disabled={!pullRequest.approved}
                        text={`Merge '${pullRequest.sourceBranch}' into '${pullRequest.targetBranch}'`}
                        onClick={async () => {
                            setMerging(true)
                            await api.mergePullRequest(projectName, pullRequestName)
                            setPullRequest({...pullRequest, merged: true})
                            setMerging(false)
                        }}
                    />
                }
            </Section>
        </Grid>
    )
}

export default PullRequestViewer